import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import whatsapp from '../../images/whatsapp-business-svgrepo-com.png';
import message from '../../images/mail.png';
import internet from '../../images/world-wide-web.png';
import feedback from '../../images/review.png';

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>AppliViu | Products</title>
        <meta name="description" content="Checkout AppliViu product and contact us for more details." />
        <meta
          name="keywords"
          content="Appliviu, Virtual Trial, Virtual Photoshoot, 3D Avatar, virtual photoshoot appliviu, E-commerce PhotoShoot, Virtual try-on, 
          virtual photoshoot app, virtual photoshoot ideas, ai virtual photoshoot, shutter virtual photoshoot, virtual assistant photoshoot, 
          Photoshoot Assistant virtually, modelverse, model verse, virtual photo meaning , adobe virtual photography, virtual photography meaning
          3d model verse, model photshoot virutally "
        />
        <link rel="canonical" href="/ourProducts" />
      </Helmet>
      <div className="bg-gradient-to-b from-[#192732] to-[#255f92]">
        <div className="flex flex-col justify-center items-center py-24">
          <ul className="text-center space-y-8 ">

            <li>
              <Link to="https://wa.me/919717288779?text=Hi%20Appliviu!%20I%E2%80%99m%20interested%20in%20your%20virtual%20photoshoot%20services.%20Can%20you%20please%20provide%20more%20details?" target="_blank">
                <div className="border-2 rounded-full bg-white bg-opacity-25 border-white border-opacity-25 w-[15rem] p-2 flex items-center justify-center">
                  <button className="flex flex-col items-center">
                    <img className="w-8 h-8 " src={whatsapp} alt="WhatsApp" />
                    <p className="text-black font-semibold">Chat With Us</p>
                  </button>
                </div>
              </Link>
            </li>

            <li>
              <Link to="/contactus" target="_blank">
                <div className="border-2 rounded-full bg-white bg-opacity-25 border-white border-opacity-25 w-[15rem] p-2 flex items-center justify-center">
                  <button className="flex flex-col items-center">
                    <img className="w-8 h-8  " src={message} alt="Enquire" />
                    <p className="text-black  font-semibold">Share Your Details</p>
                  </button>
                </div>
              </Link>

            </li>


            <li>
              <Link to="/" target="_blank">
                <div className="border-2 rounded-full bg-white bg-opacity-25 border-white border-opacity-25 w-[15rem] p-2 flex items-center justify-center">
                  <button className="flex flex-col items-center">
                    <img className="w-8 h-8 " src={internet} alt="Website" />
                    <p className="text-black font-semibold">Appliviu.com</p>
                  </button>
                </div>

              </Link>
            </li>

            <li>
              <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdguhwZM7mdwmE1ipNkQYAcf75T4ocXUB1ozg3lD_itSQDhdg/viewform" target="_blank">
                <div className="border-2 rounded-full bg-white bg-opacity-25 border-white border-opacity-25 w-[15rem] p-2 flex items-center justify-center">
                  <button className="flex flex-col items-center">
                    <img className="w-8 h-8 " src={feedback} alt="Feedback" />
                    <p className="text-black font-semibold">Give Your Feedback</p>
                  </button>
                </div>

              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Contact;
